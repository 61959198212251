<template>
  <AppMaskField
    label="CEP"
    mask="#####-###"
    v-model="cep"
    @click:prepend-inner="search()"
    :loading="loading"
    :readonly="readonly"
  >
    <template v-slot:append-inner>
      <v-icon
        v-if="!readonly"
        color="primary"
        @click.stop="search()"
        icon="mdi:mdi-search-web"
      />
    </template>
  </AppMaskField>
</template>
<script setup>
import axios from "axios";

import { debounce } from "lodash";

const props = defineProps({ 
  modelValue: {},
  readonly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["address", "update:modelValue"]);

const loading = ref(false);

const cep = ref(null);

watch(
  () => props.modelValue,
  (newValue) => (cep.value = newValue)
);

onMounted(() => (cep.value = props.modelValue));

const search = async () => {
  if (cep.value.length) {
    loading.value = true;

    cep.value = cep.value.replace(/[^\d]+/g, "");

    await axios
      .get(`https://viacep.com.br/ws/${cep.value}/json/`)
      .then((response) => {
        emitAddress(response);
        loading.value = false;
      })
      .catch((error) => (loading.value = false));

    emit("update:modelValue", cep.value);
  }
};

const emitAddress = (response) => {
  emit("address", {
    city: response.data.localidade,
    city_code: response.data.ibge,
    state: response.data.uf,
    district : response.data.bairro,
    complement : response.data.complemento,
    address : response.data.logradouro,
  });
};

</script>